<template>
    <span class="searchRowContent">
        <Svgs color="#C0C4CD" classes="searchRowSvg" name="iconsousuo"></Svgs>
        <span class="searchRowInput">
            <input type="text" :placeholder="placeholder" :disabled="disabled" v-model="val" :id="'SearchRow'+tag"
                   :style="{
                         width:width,
                         }"
                   @input="changeModel">
        </span>
            <Svgs color="#efefef"
                  classes="searchRowDeleteSvg"
                  v-if="val"
                  name="iconshanchu3"
                  @click.native="deleteInput"></Svgs>
    </span>
</template>

<script>
    export default {
        name: 'SearchRow',
        props: [
            'placeholder',//默认文字
            'width',//搜索框宽度
            'value',//绑定字段
            'disabled'
        ],
        data() {
            return {
                val: '',
                tag: ''
            }
        },
        created() {
            let math = Math.random().toString().replace('0.', '');
            let date = new Date().getTime();
            this.tag = math + date;
            this.val = this.setInputValue
        },
        methods: {
            changeModel() {
                this.$emit('change', this.val);
                this.$emit('search-call', this.val);
            },
            deleteInput() {
                this.val = '';
                this.$emit('change', this.val);
                this.$emit('search-call', this.val);
            }
        },
        watch: {
            'value': function () {
                this.val = this.setInputValue;
            }
        },
        computed: {
            setInputValue() {
                return this.value;
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
    }
</script>

<style lang="scss">
    .searchRowContent {
        position: relative;
        display: inline-block;
        margin-left: 0;
        height: .35rem;
    }
    .searchRowContent:after {
        display: block;
        clear: both;
        content: "";
        visibility: hidden;
        height: 0
    }

    .searchRowContent input {
        border-radius: 。05rem;
        background: #f9f9f9f9;
        border: 1px solid #F5F5F5;
        height: .35rem;
        line-height: .35rem;
        width: 2.6rem;
        float: left;
        text-align: left;
        outline: none;
        padding: 0 .35rem 0 .3rem;
        font-size: .13rem;
    }

    .searchRowSvg {
        position: absolute;
        left: .12rem;
        top: .11rem;
        width: .15rem !important;
        height: .15rem !important;
        z-index: 2;
    }

    .searchRowInput {
        position: relative;
    }

    .searchRowDeleteSvg {
        position: absolute;
        right: .1rem;
        top: .08rem;
    }
</style>